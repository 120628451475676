exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-blog-post-jsx-content-file-path-content-posts-2023-01-16-github-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/vercel/path0/content/posts/2023-01-16-github/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-content-posts-2023-01-16-github-index-md" */),
  "component---src-templates-case-studies-jsx": () => import("./../../../src/templates/case-studies.jsx" /* webpackChunkName: "component---src-templates-case-studies-jsx" */),
  "component---src-templates-case-study-jsx-content-file-path-content-case-studies-april-app-index-md": () => import("./../../../src/templates/case-study.jsx?__contentFilePath=/vercel/path0/content/case-studies/april-app/index.md" /* webpackChunkName: "component---src-templates-case-study-jsx-content-file-path-content-case-studies-april-app-index-md" */),
  "component---src-templates-case-study-jsx-content-file-path-content-case-studies-april-assistant-index-md": () => import("./../../../src/templates/case-study.jsx?__contentFilePath=/vercel/path0/content/case-studies/april-assistant/index.md" /* webpackChunkName: "component---src-templates-case-study-jsx-content-file-path-content-case-studies-april-assistant-index-md" */)
}

